import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Input from "../../components/input/Input";
import Progress from "../../components/progress/Progress";
import Spinner from "../../components/spinner/Spinner";
import "./verification.scss";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import config from "../../config.json";
import getCurrentDate from "../../components/utils/Utils";
import KlaviyoScript from "../../components/common/KlaviyoScript";

function Verification() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [showEmailWrapper, setShowEmailWrapper] = useState(true);
  let businessCustomerOnboardingUrl = window.localStorage.getItem(
    config.businessCustomerOnboardingUrl
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEmail = (value) => {
    setEmail(value);
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!regex.test(value)) {
      return setEmailError("Email address is not valid!");
    } else {
      setEmailError("");
    }
  };

  const handleEmailSubmitBtnDisable = () => {
    if (email !== "" && emailError === "") {
      return false;
    } else {
      return true;
    }
  };

  const handleEmailVerify = () => {
    setLoading(true);
    handleClose();
    setIsVerified(true);
    const actionCodeSettings = {
      url: businessCustomerOnboardingUrl,
      handleCodeInApp: true,
    };

    const auth = getAuth();
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        setShowEmailWrapper(false);
        window.localStorage.setItem(config.emailForSignIn, email);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  return (
    <>
      {renderSpinner()}
      <ToastContainer />

      <Progress second={false} third={true} />
      {isVerified && (
        <KlaviyoScript isCreate={true} email={email} date={getCurrentDate()} />
      )}
      <div className="verification">
        {showEmailWrapper ? (
          <Container fluid>
            <Row>
              <Col>
                <p>
                  To register as a new business user, we need to have your
                  (Personal / Business) Email Address (Active & Accessible only
                  by your business)
                </p>
              </Col>
            </Row>
            <Row>
              <Col> </Col>
              <Col md={5}>
                <div className="input-wrapper">
                  <Input
                    label="Email:"
                    type="String"
                    name="profileFullName"
                    value={email}
                    onChange={(e) => handleEmail(e.target.value)}
                    error={emailError}
                  />
                </div>
              </Col>
              <Col md={2}>
                <button
                  className="btn submit-btn"
                  onClick={handleShow}
                  disabled={handleEmailSubmitBtnDisable()}
                >
                  Submit
                </button>
              </Col>
              <Col> </Col>
            </Row>
          </Container>
        ) : (
          <div className="email-sent">
            <p>
              An Email has been sent to <span>{email}</span>.
              <br />
              Please check your email and follow the provided instructions to
              continue the registration.
            </p>
          </div>
        )}
      </div>
      <Modal show={show} className="modal-popup-custom" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Email Verification:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            We are about to send your unique registration link to{" "}
            <span>{email}</span>.
            <br />
            Is this email address confirmed?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="cancel" onClick={handleClose}>
            Edit Address
          </Button>
          <Button variant="primary" onClick={handleEmailVerify}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Verification;
