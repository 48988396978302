import { useEffect } from "react";
import { log } from "../utils/Utils";
import config from "../../config.json";
import Moment from "moment";

const KlaviyoScript = ({
  isCreate,
  email,
  date,
  name,
  lastName,
  organization,
  gender,
  dob,
  referredBy,
  externalId,
  phone,
}) => {
  const klaviyoApiPublicKey = window.localStorage.getItem(
    config.klaviyoApiPublicKey
  );

  const createProfile = async () => {
    const url = `https://a.klaviyo.com/client/profiles/?company_id=${klaviyoApiPublicKey}`;
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        revision: "2024-07-15",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: {
          type: "profile",
          attributes: {
            email: email,
            properties: {
              user_type: "Business",
              sign_up_date: date,
              platform_type: "web",
            },
          },
        },
      }),
    };

    await fetch(url, options)
      .then((res) => log(res))
      .catch((err) => log(err));
  };

  const createProfileEvent = async () => {
    const url = `https://a.klaviyo.com/client/events/?company_id=${klaviyoApiPublicKey}`;
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        revision: "2024-07-15",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: {
          type: "event",
          attributes: {
            properties: {},
            metric: {
              data: {
                type: "metric",
                attributes: { name: "Sign Up Initiated" },
              },
            },
            profile: {
              data: {
                type: "profile",
                attributes: { email: email, properties: {} },
              },
            },
          },
        },
      }),
    };

    await fetch(url, options)
      .then((res) => log(res))
      .catch((err) => log(err));
  };

  const compeleteUserProfile = async () => {
    const url = `https://a.klaviyo.com/client/profiles/?company_id=${klaviyoApiPublicKey}`;
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        revision: "2024-07-15",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: {
          type: "profile",
          attributes: {
            email: email,
            properties: {
              phone_number: phone,
              first_name: name,
              last_name: lastName,
              organization: organization,
              gender: gender,
              dob: Moment(dob).format("YYYY-MM-DD"),
              user_type: "Business",
              sign_up_date: date,
              is_profile_completed: "true",
              referred_by: referredBy,
              platform_type: "web",
              external_id: externalId,
              loyalty_tier: "Basic",
            },
          },
        },
      }),
    };
    await fetch(url, options)
      .then((res) => log(res))
      .catch((err) => log(err));
  };

  const compeleteUserProfileEvent = async () => {
    const url = `https://a.klaviyo.com/client/events/?company_id=${klaviyoApiPublicKey}`;
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        revision: "2024-07-15",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: {
          type: "event",
          attributes: {
            properties: {},
            metric: {
              data: {
                type: "metric",
                attributes: { name: "Sign Up Completed" },
              },
            },
            profile: {
              data: {
                type: "profile",
                attributes: { email: email, properties: {} },
              },
            },
          },
        },
      }),
    };

    await fetch(url, options)
      .then((res) => log(res))
      .catch((err) => log(err));
  };

  useEffect(() => {
    if (isCreate) {
      createProfile();
      createProfileEvent();
    } else {
      compeleteUserProfile();
      compeleteUserProfileEvent();
    }
  }, []);

  return null;
};

export default KlaviyoScript;
