import { getValue, fetchAndActivate } from "firebase/remote-config";
import { remoteConfig } from "../../index";
import config from "../../config.json";
import { log } from "../../components/utils/Utils";

export const getRemoteConfigData = async () => {
  window.localStorage.clear()
  try {
    remoteConfig.settings.minimumFetchIntervalMillis = 0;
    await fetchAndActivate(remoteConfig);
    const bearerWebsiteBaseUrl = getValue(remoteConfig, config.bearerWebsiteBaseUrl).asString();
    window.localStorage.setItem(config.bearerWebsiteBaseUrl, bearerWebsiteBaseUrl);
    log("bearerWebsiteBaseUrl:" + bearerWebsiteBaseUrl);
    // ----------------------------------------------------------------
    const businessCustomerPpUrl = getValue(remoteConfig, config.businessCustomerPpUrl).asString();
    window.localStorage.setItem(config.businessCustomerPpUrl, businessCustomerPpUrl);
    log("businessCustomerPpUrl:" + businessCustomerPpUrl);
    // ----------------------------------------------------------------
    const environment = getValue(remoteConfig, config.environment).asString();
    window.localStorage.setItem(config.environment, environment);
    log("environment:" + environment);
    //----------------------------------------------------------------
    const businessCustomerTcUrl = getValue(remoteConfig, config.businessCustomerTcUrl).asString();
    window.localStorage.setItem(config.businessCustomerTcUrl, businessCustomerTcUrl);
    log("businessCustomerTcUrl:" + businessCustomerTcUrl);
    //----------------------------------------------------------------
    const latestTcPpVersionBusinessCustomers = getValue(remoteConfig, config.latestTcPpVersionBusinessCustomers).asString();
    window.localStorage.setItem(config.latestTcPpVersionBusinessCustomers, latestTcPpVersionBusinessCustomers);
    log("latestTcPpVersionBusinessCustomers:" + latestTcPpVersionBusinessCustomers);
    //----------------------------------------------------------------
    const businessCustomerLandingpageUrl = getValue(remoteConfig, config.businessCustomerLandingpageUrl).asString();
    window.localStorage.setItem(config.businessCustomerLandingpageUrl, businessCustomerLandingpageUrl);
    log("businessCustomerLandingpageUrl:" + businessCustomerLandingpageUrl);
    //----------------------------------------------------------------
    const businessCustomerOnboardingUrl = getValue(remoteConfig, config.businessCustomerOnboardingUrl).asString();
    window.localStorage.setItem(config.businessCustomerOnboardingUrl, businessCustomerOnboardingUrl);
    log("businessCustomerOnboardingUrl:" + businessCustomerOnboardingUrl);
    //----------------------------------------------------------------
    const whatsappLiveSupportNumber = getValue(remoteConfig, config.whatsappLiveSupportNumber).asString();
    window.localStorage.setItem(config.whatsappLiveSupportNumber, whatsappLiveSupportNumber);
    log("whatsappLiveSupportNumber:" + whatsappLiveSupportNumber);
    //----------------------------------------------------------------
    const businessCustomerWebappUrl = getValue(remoteConfig, config.businessCustomerWebappUrl).asString();
    window.localStorage.setItem(config.businessCustomerWebappUrl, businessCustomerWebappUrl);
    log("businessCustomerWebappUrl:" + businessCustomerWebappUrl);
    //----------------------------------------------------------------
    const bearerCloudfunctionsIsRunning = getValue(remoteConfig, config.bearerCloudfunctionsIsRunning).asString();
    window.localStorage.setItem(config.bearerCloudfunctionsIsRunning, bearerCloudfunctionsIsRunning);
    log("bearerCloudfunctionsIsRunning:" + bearerCloudfunctionsIsRunning);
    //----------------------------------------------------------------
    const bearerCloudfunctionsStatusMessageText = getValue(remoteConfig, config.bearerCloudfunctionsStatusMessageText).asString();
    window.localStorage.setItem(config.bearerCloudfunctionsStatusMessageText, bearerCloudfunctionsStatusMessageText);
    log("bearerCloudfunctionsStatusMessageText:" + bearerCloudfunctionsStatusMessageText);
    //----------------------------------------------------------------
    const bearerCloudfunctionsStatusMessageButtonAction = getValue(remoteConfig, config.bearerCloudfunctionsStatusMessageButtonAction).asString();
    window.localStorage.setItem(config.bearerCloudfunctionsStatusMessageButtonAction, bearerCloudfunctionsStatusMessageButtonAction);
    log("bearerCloudfunctionsStatusMessageButtonAction:" + bearerCloudfunctionsStatusMessageButtonAction);
    //----------------------------------------------------------------
    const riderSupportWebpageUrl = getValue(remoteConfig, config.riderSupportWebpageUrl).asString();
    window.localStorage.setItem(config.riderSupportWebpageUrl, riderSupportWebpageUrl);
    log("riderSupportWebpageUrl:" + riderSupportWebpageUrl);
    //----------------------------------------------------------------
    const bearerCloudfunctionsActionUrl = getValue(remoteConfig, config.bearerCloudfunctionsActionUrl).asString();
    window.localStorage.setItem(config.bearerCloudfunctionsActionUrl, bearerCloudfunctionsActionUrl);
    log("bearerCloudfunctionsActionUrl:" + bearerCloudfunctionsActionUrl);
    //----------------------------------------------------------------
    const bearerBusinessCustomerOnboardingIsRunning = getValue(remoteConfig, config.bearerBusinessCustomerOnboardingIsRunning).asString();
    window.localStorage.setItem(config.bearerBusinessCustomerOnboardingIsRunning, bearerBusinessCustomerOnboardingIsRunning);
    log("bearerBusinessCustomerOnboardingIsRunning:" + bearerBusinessCustomerOnboardingIsRunning);
    //----------------------------------------------------------------
    const bearerBusinessCustomerOnboardingStatusMessageText = getValue(remoteConfig, config.bearerBusinessCustomerOnboardingStatusMessageText).asString();
    window.localStorage.setItem(config.bearerBusinessCustomerOnboardingStatusMessageText, bearerBusinessCustomerOnboardingStatusMessageText);
    log("bearerBusinessCustomerOnboardingStatusMessageText:" + bearerBusinessCustomerOnboardingStatusMessageText);
    //----------------------------------------------------------------
    const bearerBusinessCustomerOnboardingStatusMessageButtonAction = getValue(remoteConfig, config.bearerBusinessCustomerOnboardingStatusMessageButtonAction).asString();
    window.localStorage.setItem(config.bearerBusinessCustomerOnboardingStatusMessageButtonAction, bearerBusinessCustomerOnboardingStatusMessageButtonAction);
    log("bearerBusinessCustomerOnboardingStatusMessageButtonAction:" + bearerBusinessCustomerOnboardingStatusMessageButtonAction);
    //----------------------------------------------------------------
    const businessCustomerSupportWebpageUrl = getValue(remoteConfig, config.businessCustomerSupportWebpageUrl).asString();
    window.localStorage.setItem(config.businessCustomerSupportWebpageUrl, businessCustomerSupportWebpageUrl);
    log("businessCustomerSupportWebpageUrl:" + businessCustomerSupportWebpageUrl);
    //----------------------------------------------------------------
    const bearerBusinessCustomerOnboardingActionUrl = getValue(remoteConfig, config.bearerBusinessCustomerOnboardingActionUrl).asString();
    window.localStorage.setItem(config.bearerBusinessCustomerOnboardingActionUrl, bearerBusinessCustomerOnboardingActionUrl);
    log("bearerBusinessCustomerOnboardingActionUrl:" + bearerBusinessCustomerOnboardingActionUrl);
    //----------------------------------------------------------------
    //----------------------------------------------------------------
    const klaviyoApiPublicKey = getValue(remoteConfig, config.klaviyoApiPublicKey).asString();
    window.localStorage.setItem(config.klaviyoApiPublicKey, klaviyoApiPublicKey);
    log("klaviyoApiPublicKey:" + klaviyoApiPublicKey);
    //----------------------------------------------------------------
  } catch (err) {
    log(err);
  }
};
