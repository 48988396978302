import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Progress from "../../components/progress/Progress";
import "./details.scss";
import Input from "../../components/input/Input";
import Select from "../../components/select/Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import profileImage from "../../assets/profile.png";
import config from "../../config.json";
import Spinner from "../../components/spinner/Spinner";
import { log } from "../../components/utils/Utils";
import { ToastContainer, toast } from "react-toastify";
import Resizer from "react-image-file-resizer";
import * as services from "./detailService";
import Moment from "moment";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { signOut, getAuth, updatePassword } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import WhatsAppFeedBack from "../../components/common/WhatsAppFeedBack";
import KlaviyoScript from "../../components/common/KlaviyoScript";
import getCurrentDate from "../../components/utils/Utils";
import { Helmet } from "react-helmet";

function Details() {
  const [isLoading, setLoading] = useState(false);
  const regex = /^[A-Za-z]*$/;
  const numberRegex = /^[0-9\b]+$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

  let privacyPolicyUrl = window.localStorage.getItem(
    config.businessCustomerPpUrl
  );
  let bearerCustomerAgreement = window.localStorage.getItem(
    config.businessCustomerTcUrl
  );
  let email = window.localStorage.getItem(config.emailForSignIn);
  let latestTcPpVersionBusinessCustomers = window.localStorage.getItem(
    config.latestTcPpVersionBusinessCustomers
  );

  let businessCustomerWebappUrl = window.localStorage.getItem(
    config.businessCustomerWebappUrl
  );

  const ip = localStorage.getItem("ip");

  const storage = getStorage();
  const [successMessage, setSuccessMessage] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [repassword, setRepassword] = useState("");
  const [repasswordError, setRepasswordError] = useState("");

  const [legalName, setLegalName] = useState("");
  const [legalNameError, setLegalNameError] = useState("");

  const [ABN, setABN] = useState("");
  const [ABNError, setABNError] = useState("");

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [areaCode, setAreaCode] = useState("");
  const [areaCodeError, setAreaCodeError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [inviteCode, setInviteCode] = useState("");

  const [selectedGender, setGenderSelect] = useState("Male");
  const [referredSelect, setReferredSelect] = useState("");
  const [birthday, setBirthday] = useState("");
  const [birthdayError, setBirthdayError] = useState("");

  const [agreePolicy, setAgreePolicy] = useState(false);

  const [file, setFile] = useState(profileImage);
  const [isPasswordSet, setPasswordSet] = useState(false);

  const [counter, setCounter] = useState(240);
  const [counterClass, setCounterClass] = useState("remaing-time green");
  const [dialogOpen, setDialogOpen] = useState(false);

  const userUID = window.localStorage.getItem("uid");
  const imageUrl = "customerUserAvatars/" + userUID + "/" + userUID + ".JPEG";

  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(counter - 1);
    }, 1000);
    if (counter < 120) {
      setCounterClass("remaing-time orange");
    }
    if (counter < 60) {
      setCounterClass("remaing-time red");
    }
    if (counter === 0) {
      clearTimeout(timer);
      setDialogOpen(true);
    }
    if (isPasswordSet) {
      clearTimeout(timer);
    }
  }, [counter, counterClass]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showModal, setShowModal] = useState(false);
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        "JPEG",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        30,
        30
      );
    });

  const handleChangeAvatarChange = async (e) => {
    setLoading(true);
    const imageFile = URL.createObjectURL(e.target.files[0]);
    try {
      const resizedImage = await resizeFile(e.target.files[0]);
      setFile(imageFile);
      const metadata = {
        contentType: "image/jpeg",
      };
      const storageRef = ref(storage, imageUrl);
      const uploadTask = uploadBytesResumable(
        storageRef,
        resizedImage,
        metadata
      );

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          toast.error(error);
        },
        () => {}
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  let gender = [
    { id: "0", name: "Male" },
    { id: "1", name: "Female" },
    { id: "2", name: "Non-binary" },
    { id: "3", name: "Prefer not to say" },
  ];

  let referredBy = [
    { id: "0", name: "Social Media Ads" },
    { id: "1", name: "Phone Calls" },
    { id: "2", name: "Email & Newsletters" },
    { id: "3", name: "Search Engine" },
    { id: "4", name: "Referral Code" },
    { id: "5", name: "Flyers (Public Campaigns)" },
    { id: "6", name: "Other" },
  ];

  const abnValidator = (myAbn) => {
    let result = false;
    let ABN;
    try {
      ABN = myAbn;
    } catch (e) {
      ABN = "";
    }
    if (ABN.length === 11) {
      try {
        let control = new Array(11);
        control[0] = (Number(ABN[0], false) - 1) * 10;
        control[1] = Number(ABN[1], false);
        control[2] = Number(ABN[2], false) * 3;
        control[3] = Number(ABN[3], false) * 5;
        control[4] = Number(ABN[4], false) * 7;
        control[5] = Number(ABN[5], false) * 9;
        control[6] = Number(ABN[6], false) * 11;
        control[7] = Number(ABN[7], false) * 13;
        control[8] = Number(ABN[8], false) * 15;
        control[9] = Number(ABN[9], false) * 17;
        control[10] = Number(ABN[10], false) * 19;
        let total = 0;
        for (let i = 0; i < 11; i++) {
          total += control[i];
        }

        if (total % 89 === 0) result = true;
        else result = false;
      } catch {
        result = false;
      }
    } else result = false;

    if (result) {
      setABNError("");
    } else {
      setABNError("ABN is not valid");
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleMobileNumber = (value) => {
    if (value.length < 13) {
      const result = value.replace(/\D/g, "");
      if (value.length === 1) {
        if (value === "0") {
          setMobile("+61");
        } else if (value === "+") {
          setMobile("+61");
        } else {
          setMobile(`+61${result}`);
        }
      } else if (result.length < 12) {
        if (result.length === 3 && result.slice(-1) === "0") {
        } else {
          setMobile(`+${result}`);
          if (value.length === 12) {
            setMobileError("");
          } else {
            setMobileError("12 Digits (Beginning with +61)");
          }
        }
      }
    }
  };

  const updateUserPassword = () => {
    if (password === "" || passwordError !== "") {
      return setPasswordError(
        "Password must be 8 characters min At least 1 Uppercase, 1 Lowercase, 1 Number and 1 Special character"
      );
    }

    if (repassword === "" || repasswordError !== "") {
      return setRepasswordError("Please make sure your Passwords match.");
    }

    setLoading(true);

    const auth = getAuth();
    const user = auth.currentUser;
    updatePassword(user, password)
      .then(() => {
        setPasswordSet(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  const getRefferalCode = () => {
    var refferalCode = "";
    if (referredSelect === "" || referredSelect === "Select your answer") {
      return toast.error("Please give answer in how to hear about bearer ");
    } else if (referredSelect === "Other") {
      refferalCode = "";
    } else if (referredSelect === "Referral Code") {
      if (inviteCode === "") {
        return toast.error("Please enter invite code");
      } else {
        refferalCode = inviteCode;
      }
    } else {
      refferalCode = referredSelect;
    }
    return refferalCode;
  };

  const handleSubmitDetail = async () => {
    if (legalName === "" || legalNameError !== "") {
      return setLegalNameError("Minimum 2 letters (English Only)");
    }

    if (ABN === "" || ABNError !== "") {
      return setABNError("ABN must be a 11 digit number");
    }

    if (areaCode.length !== 4 || areaCodeError !== "") {
      return setAreaCodeError("Area code must be 4 digit number");
    }

    if (name === "" || nameError !== "") {
      return setNameError("Minimum 2 letters (English Only)");
    }

    if (lastName === "" || lastNameError !== "") {
      return setLastNameError("Minimum 2 letters (English Only)");
    }

    const sortedBirth = Moment(birthday).format("YYYY-MM-DD");
    if (sortedBirth === "") {
      return setBirthdayError("Please enter your birthday");
    }

    if (selectedGender === "" || selectedGender === "Select your gender") {
      return toast.error("Please select your gender");
    }

    if (mobile === "" || mobileError !== "") {
      return setMobileError("12 Digits (Beginning with +61)");
    }

    if (!agreePolicy) {
      return toast.error(
        "Please agree Bearer Privacy Policy and Bearer Business Customer Agreement"
      );
    }
    setLoading(true);
    try {
      const result = await services.registerBusinessCustomer(
        legalName,
        email,
        name,
        lastName,
        mobile,
        sortedBirth,
        selectedGender,
        ABN,
        areaCode,
        getRefferalCode(),
        imageUrl,
        latestTcPpVersionBusinessCustomers,
        ip
      );
      setLoading(false);
      if (result) {
        log(result.data);
        switch (result.data.code) {
          case 200:
            const auth = getAuth();
            signOut(auth).then(() => {});
            setShowModal(true);
            setSuccessMessage(result.data.message);
            setIsVerified(true);
            if (typeof window.fbq !== "undefined") {
              window.fbq("track", "Signup");
            }
            return;
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const handleSubmitSuccess = () => {
    window.location.replace(businessCustomerWebappUrl);
  };

  const handleSessionExpired = () => {
    let businessCustomerOnboardingUrl = window.localStorage.getItem(
      config.businessCustomerOnboardingUrl
    );
    window.localStorage.removeItem(config.emailForSignIn);
    window.location.replace(businessCustomerOnboardingUrl);
  };

  function secondsToTime(e) {
    const m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, "0");

    return m + ":" + s;
  }

  return (
    <>
      {renderSpinner()}
      <ToastContainer />
      <Progress second={false} third={false} />
      {isVerified && (
        <KlaviyoScript
          phone={mobile}
          name={name}
          lastName={lastName}
          organization={legalName}
          gender={selectedGender}
          dob={birthday}
          referredBy={getRefferalCode()}
          externalId={userUID.slice(-7)}
          isCreate={false}
          email={email}
          date={getCurrentDate()}
        />
      )}
      <div className="details">
        {isPasswordSet ? (
          <Container>
            <WhatsAppFeedBack />
            <Row>
              <div className="title-wrapper">
                <span className="title">Business Logo</span>
              </div>
              <Col xs={12}>
                <div className="avatar-image">
                  <img src={file} alt="file" />
                  <input
                    id="files"
                    type="file"
                    accept="image/*"
                    onChange={handleChangeAvatarChange}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <div className="title-wrapper">
                <span className="title">Business Details</span>
              </div>
              <Col xs={12}>
                <div className="input-wrapper">
                  <Input
                    label="Legal (Business) Name:"
                    type="string"
                    name="name"
                    value={legalName}
                    error={legalNameError}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.trim().length > 0) {
                        setLegalName(value);
                        if (value.replace(/\s/g, "").length > 1) {
                          setLegalNameError("");
                        } else {
                          setLegalNameError("Minimum 2 letters (English Only)");
                        }
                      } else {
                        setLegalName("");
                      }
                    }}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div className="input-wrapper">
                  <Input
                    label="ABN (Australian Business Number):"
                    type="string"
                    name="ABN"
                    value={ABN}
                    onChange={(e) => {
                      const value = e.target.value.replace(/ /g, "");
                      if (value.length < 12) {
                        setABN(value);
                      } else {
                        setABNError("ABN must be a 11 digit number");
                      }
                      abnValidator(value);
                    }}
                    error={ABNError}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div className="input-wrapper">
                  <Input
                    label="Post Code:"
                    type="string"
                    name="areaCode"
                    value={areaCode}
                    error={areaCodeError}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.trim().length > 0) {
                        if (
                          value.replace(/\s/g, "").length < 5 &&
                          numberRegex.test(value)
                        ) {
                          setAreaCode(value);
                          setAreaCodeError("");
                        }
                      } else {
                        setAreaCode("");
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <div className="title-wrapper">
                <span className="title">Business Representative</span>
              </div>
              <Col xs={12}>
                <div className="input-wrapper">
                  <Input
                    label="First Name:"
                    type="string"
                    name="name"
                    value={name}
                    error={nameError}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.trim().length > 0) {
                        setName(value);
                        if (
                          value.replace(/\s/g, "").length > 1 &&
                          regex.test(value.replace(/\s/g, ""))
                        ) {
                          setNameError("");
                        } else {
                          setNameError("Minimum 2 letters (English Only)");
                        }
                      } else {
                        setName("");
                      }
                    }}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div className="input-wrapper">
                  <Input
                    label="Last Name:"
                    type="string"
                    name="lastName"
                    error={lastNameError}
                    value={lastName}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.trim().length > 0) {
                        setLastName(value);
                        if (
                          value.replace(/\s/g, "").length > 1 &&
                          regex.test(value.replace(/\s/g, ""))
                        ) {
                          setLastNameError("");
                        } else {
                          setLastNameError("Minimum 2 letters (English Only)");
                        }
                      } else {
                        setLastName("");
                      }
                    }}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div className="input-wrapper">
                  <label htmlFor="dateTime">DOB:</label>
                  <DatePicker
                    selected={birthday}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => setBirthday(date)}
                    maxDate={
                      new Date(
                        new Date().getFullYear() - 18,
                        new Date().getMonth() - 1,
                        new Date().getDate()
                      )
                    }
                    className="form-control"
                    error={birthdayError}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="input-wrapper">
                  <Select
                    name="gender"
                    label="Gender:"
                    options={gender}
                    onChange={(e) => setGenderSelect(e.target.value)}
                    disableSelect={false}
                    disabledDefaultValue={false}
                    defaultValue="Select your gender"
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div className="input-wrapper">
                  <div className="input-wrapper">
                    <Input
                      label="Business Phone:"
                      type="String"
                      name="mobile number"
                      value={mobile}
                      onChange={(e) => handleMobileNumber(e.target.value)}
                      error={mobileError}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="input-wrapper">
                  <Select
                    name="gender"
                    label="How did you hear about Bearer?"
                    options={referredBy}
                    onChange={(e) => setReferredSelect(e.target.value)}
                    disableSelect={false}
                    disabledDefaultValue={false}
                    defaultValue="Select your answer"
                  />
                </div>
              </Col>
              {referredSelect === "Referral Code" && (
                <Col xs={12}>
                  <div className="input-wrapper">
                    <Input
                      label="Invite Code:"
                      type="string"
                      name="invite code"
                      value={inviteCode}
                      onChange={(e) => setInviteCode(e.target.value)}
                    />
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              <div className="bottom-info">
                <p>
                  <label>
                    <input
                      type="checkbox"
                      checked={agreePolicy}
                      onChange={() => setAgreePolicy((prev) => !prev)}
                    />
                  </label>
                  I’ve read and agreed{" "}
                  <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">
                    Bearer Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href={bearerCustomerAgreement}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Bearer Business Customer Agreement
                  </a>{" "}
                  and I hereby declare, all of the information I have provided
                  is complete and correct. I am aware that missing or incomplete
                  information, whether deliberate or the result of negligence,
                  may either result in rejection of my application or [Bearer]
                  account(s) suspension for further investigations and legal
                  actions. Also I understand that submitting this application
                  will not guarantee that it would be approved and will not mean
                  that I have been accepted to place delivery orders as a
                  Business Customer or being allowed by the platform to access
                  the applications (Mobile and Web) designed and deemed to be
                  used by Bearer users.
                  <br />
                  <br />
                  The Electronic Transactions (Victoria) Act 2000 establishes
                  the regulatory framework for transactions to be completed
                  electronically. By submitting this application, you have
                  indicated your approval of the contents of this electronic
                  communication.
                </p>
              </div>
            </Row>
            <Row>
              <button
                className="btn submit-btn submit-page"
                onClick={handleSubmitDetail}
              >
                Submit
              </button>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row>
              <div className="title-wrapper">
                <span className="title">Account Security</span>
              </div>
              <div>
                <h3 className={counterClass}>{secondsToTime(counter)}</h3>
              </div>
              <Col md={4} xs={12}>
                <div className="input-wrapper">
                  <Input
                    label="Password:"
                    type="password"
                    name="password"
                    value={password}
                    error={passwordError}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      if (passwordRegex.test(e.target.value)) {
                        setPasswordError("");
                      } else {
                        setPasswordError(
                          "Password must be 8 characters min At least 1 Uppercase, 1 Lowercase, 1 Number and 1 Special character"
                        );
                      }
                      if (repassword !== "") {
                        if (e.target.value !== repassword) {
                          setRepasswordError(
                            "Please make sure your Passwords match."
                          );
                        } else {
                          setRepasswordError("");
                        }
                      }
                    }}
                  />
                </div>
              </Col>
              <Col md={4} xs={12}>
                <div className="input-wrapper">
                  <Input
                    label="Re-Type Password:"
                    type="password"
                    name="rpassword"
                    value={repassword}
                    error={repasswordError}
                    onChange={(e) => {
                      setRepassword(e.target.value);
                      if (e.target.value !== password) {
                        setRepasswordError(
                          "Please make sure your Passwords match."
                        );
                      } else {
                        setRepasswordError("");
                      }
                    }}
                  />
                </div>
              </Col>
              <Col md={3} lg={3} sm={3} xs={12}>
                <button
                  className="btn submit-btn set-password"
                  onClick={updateUserPassword}
                >
                  Set Password
                </button>
              </Col>
            </Row>
          </Container>
        )}
      </div>
      <Modal show={showModal} className="modal-popup-custom">
        <Modal.Header>
          <Modal.Title>Welcome to Bearer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{successMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmitSuccess}>
            ᵔᴥᵔ
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={dialogOpen} className="modal-popup-custom">
        <Modal.Body>
          <Modal.Title>Session expired</Modal.Title>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSessionExpired}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Details;
