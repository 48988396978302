import { useEffect } from "react";

const GoogleTagManager = () => {
  useEffect(() => {
    // Create a new script element
    const scriptElement = document.createElement("script");

    // Set the script content
    scriptElement.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MBDK77G');
    `;

    // Append the script to the head section of the document
    document.head.appendChild(scriptElement);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(scriptElement);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return null; // Since this component only adds a script, it doesn't render anything
};

export default GoogleTagManager;
