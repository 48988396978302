import React from "react";

const Input = ({
  name,
  label,
  min = 0,
  placeHolder,
  error,
  className = "",
  ...rest
}) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <input
        {...rest}
        name={name}
        id={name}
        className={`form-control ${className}`}
        placeholder={placeHolder}
        min={min}
      />
      {error && <div className="alert alert-danger custom-error">{error}</div>}
    </div>
  );
};

export default Input;
